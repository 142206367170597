<template>
    <v-container fluid>
      <v-card>
        <v-toolbar color="secondary" dense flat>
          <v-toolbar-title>{{outlet.CardName}}</v-toolbar-title>
        </v-toolbar>

        <v-card-text>
          <v-tabs right>
            <v-tab :to="`/outlet/${id}`">Summary</v-tab>
            <v-tab :to="`/outlet/${id}/calls`">Calls</v-tab>
            <v-tab :to="`/outlet/${id}/sales`">Sales</v-tab>
            <!-- <v-tab :to="`/outlet/${id}/contacts`">Contacts</v-tab> -->
          </v-tabs>

          <v-row>
            <v-divider></v-divider>
          </v-row>

          <router-view/>
        </v-card-text>
      </v-card>
    </v-container>
</template>

<script>
export default {
  data() {
    return {
      id: null,
      outlet: {}
    };
  },
  watch: {
    "$route.params.id": {
      handler: "getData",
      immediate: true
    }
  },
  methods: {
    getData(val) {
      this.id = val;
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/v1/outlet/${val}`)
        .then(res => {
          self.outlet = res;
          self.loading = false;
        })
        .catch(err => {
          console.log(err);
        });
    }
  },
  created() {

  }
};
</script>

<style lang="scss" scoped>
</style>